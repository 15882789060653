.Home-main{
  display:flex;
  justify-content: center;
  height:100vh;
  max-width:100%;
  padding:10px;
  background-color: rgb(79, 78, 80);
  color:white;
  font-weight:bold;
  overflow: hidden;
}

.Home-title-div{
  display:flex;
  flex-direction: column;
  align-items: left;
  margin-top:10rem;
  width:90%;
}

.Home-title{
  font-size:3rem;
  margin:1rem;
}

.Home-typed-word {
  max-width: fit-content;
  font-size: 1.5rem;
  overflow: hidden; 
  border-right:0.1em solid black;
  white-space: nowrap; 
  letter-spacing:.12em;
  margin-right:-1rem;
  animation: 
    typing 3s steps(40, end),
    blinking .75s step-end infinite;
    animation-iteration-count: infinite;
}

.Home-name{
  background-image: linear-gradient(
    140deg,
      #CA4246 16.666%, 
      #E16541 16.666%, 
      #E16541 33.333%, 
      #F18F43 33.333%, 
      #F18F43 50%, 
      #8B9862 50%, 
      #8B9862 66.666%
  );
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}


@keyframes typing {
  from { width: 0% }
  to { width: 90% }
}

@keyframes blinking {
  from, to { border-color: transparent }
  50% { border-color: black }; 
} 