.Footer-border{
  height:10vh;
  width:100vw;
  background-image:linear-gradient(
      #CA4246 10%, 
      #eb866b 25%,
      #e79761 40%, 
      #F18F43 55%,
      #F18F43 70%, 
      #8B9862 85%, 
      #8B9862 100%
  );
}
.Footer-main{
  width:100vw;
  height:20vh;
  display:flex;
  justify-content: center;
  align-items: center;
  bottom:0;
}

.Footer-nest{
  background-color: tan;
  display:flex;
  flex-direction: row;
  width:100%;
  height:100%;
  align-items: center;
  justify-content: center;
}

.linkedin{
  height:2.8rem;
  width:2.8rem;
  padding:0.5rem;
}

.github{
  height:2.2rem;
  width:2.2rem;
  padding:0.5rem;
}

.resume{
  height:2.7rem;
  width:2.7rem;
  padding:0.5rem;
}

/* Email button styling */

.Footer-nest :nth-child(4){
  border-radius:20px;
  height:2.2rem;
  width:2.2rem;
}
.email{
  height:2.2rem;
  width:2.2rem;
  border-radius:40px;
  padding:0.5rem;
  /* filter:brightness(0.5) contrast(200%) sepia(130) */
}
.Footer-icon:hover{
  cursor:pointer;
  transform:translateY(1%);
}

.Footer-view-code{
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: tan;
}
#Code{
  text-align: center;
}
.Footer-view-code button{
  height:2.2rem;
  width:10rem;
  font-size: 1rem;
  font-weight: 900;
  margin-bottom:1.5rem;
  border:transparent;
  border-radius:10px;
  cursor: pointer;
  background-color:#97a763;
  color:white;
  text-align: center;
}
.Footer-view-code button:hover{
  background-color:#758349;
}

@media screen and (max-width:350px){
  .Footer-main{
    height:30vh;
  }
}