@import './styles/App.css';

body {
  margin: 0;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:var(--main-background-color);
    overflow-x:hidden;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

.Link-style{
  text-decoration: none;
  color:white;
}

:root {
  --main-background-color: rgb(88, 87, 90);
}

.hidden{
  display:none;
}