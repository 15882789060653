@import './nav.css';
@import './home.css';
@import './about.css';
@import './contact.css';
@import './work.css';
@import './footer.css';

.App {
  text-align: center;
}


