.Contact-main{
  width:100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  margin-bottom:3rem;
}
.Contact-section-div{
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.Contact-section-nest{
  width:100vw;
  color:white;
  display: flex;
  flex-direction: column;
  align-items:center ;
}

.Contact-title-div{
  width:100%;
}

.Contact-title{
  font-size:2.5rem;
  padding:1rem;
  margin-bottom:0;
  border-top:1px solid white;
  border-bottom:1px solid white;
  background-image: linear-gradient(
      140deg,
      #CA4246 16%, 
      #E16541 26%, 
      #E16541 46%, 
      #E16541 83%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

/* Form Styling */

.Contact-form-main{
  width:100vw;
}

.Contact-form-div{
  display:grid;
  grid-template-columns:1fr 1fr;
  padding:1rem;
  width:100%;
}

.Form-nest-div{
  display:flex;
  flex-direction: column;
  padding:0.1rem;
  margin-top:1rem;
}

.Form-input{
  width:90%;
  height:1.7rem;
  outline:none;
  background-color: transparent;
  color:white;
  font-size:1rem;
  font-weight: bold;
  padding:0.1rem;
  margin-top:0.5rem;
  border-radius:2px;
  border:transparent;
  border-bottom:1px solid white;
}

.Form-label{
  font-size:1.2rem;
  font-weight:bold;
}

.Form-message-div{
  display:flex;
  flex-direction: column;
  align-items: left;
  width:100%;
  margin-top:3rem;
  margin-left:1rem;
}

.Form-message{
  font-size:1.2rem;
  font-weight: bold;
  resize: none;
  outline:none;
  line-height:1.5rem;
  width:95%;
  border-radius: 6px;
  box-sizing:border-box;
  padding:10px;
}

.Form-submit-div{
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:1rem;
}

.Form-submit{
  background-color:#97a763;
  color:white;
  width:10rem;
  height:2rem;
  border-radius: 5px;
  border:transparent;
  font-size:1.1rem;
  font-weight: bold;
}

.Form-submit:hover{
  cursor:pointer;
  background-color:#758349;
}

.required{
  color:red;
}


@media screen and (max-width:700px){
  .Form-message{
    width:93%;
  }
}

@media screen and (max-width:350px){
  .Contact-main{
    margin-bottom:3rem;
  }
  .Contact-title{
    font-size:2rem;
  }
  .Form-label{
    font-size: 0.9rem;
  }
  .Form-input{
    width:80%;
    height:1.6rem;
    font-size: 0.9rem;
  }
  .Form-message-div{
    margin-top:1rem;
  }
  .Form-message{
    font-size: 0.9rem;
  }
}
