.About-main{
  min-height:100vh;
  width:100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x:hidden;
}
.About-section-div{
  display:flex;
  align-items: center;
  justify-content: center;
  min-height:100vh;
  width:100%;
}

.About-section-nest{
  border:2px solid white;
  min-height:98vh;
  width:98%;
  color:white;
  display: flex;
  flex-direction: column;
  align-items:center ;
}

.About-title-div{
  width:100%;
  display:flex;
  justify-content: center;
}

.About-title{
  font-size:2.5rem;
  padding:0.5rem;
  width:85%;
  margin-bottom:0;
  border-top:2px solid white;
  border-bottom:2px solid white;
  background-image: linear-gradient(
      140deg,
      #CA4246 16%, 
      #E16541 26%, 
      #E16541 46%, 
      #E16541 83%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.About-section-p-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:0.5rem;
  width:85%;
}

.About-section-about-div{
  padding:0.5rem;
  width:85%;
}

.About-section{
  max-width:98%;
  font-size: 1.5rem;
  font-weight:600;
  line-height:2.1rem;
  letter-spacing:0;
  word-spacing:0.2rem;
}

.About-contact-section{
  border-top:3px solid white;
  border-bottom:3px solid white;
  min-width:100%;
  max-width:1000%;
  padding:1rem;
  margin-top:3rem;
  margin-bottom:1rem;
}

.About-contact-links{
  list-style-type:none;
  display:flex;
  flex-direction: row;
  justify-content: center;
  left:0;
}

.About-contact-link{
  text-decoration: underline;
  margin-right:1.2rem;
  cursor:pointer;
  font-weight: bold;
  font-size:1.1rem;

}
.About-talk{
  font-weight: bold;
  text-decoration: underline;
  padding:0.1rem;
  font-size:1.2rem;
}


/* About tech stack styling */

.About-tech-main{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom:1rem;
}

.About-tech-grid-parent{
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap:10px;
  justify-items: left;
  align-content: center;
  width:100%;
  border-radius:10px;
}
.About-tech-grid{
  border:1px solid transparent;
  border-radius:10px;
  color:white;
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  align-items: left;
  background-color:rgb(49, 48, 48);
}

.About-tech-grid > p {
  font-size:1.3rem;
  font-weight: bold;
  line-height:2rem;
  letter-spacing:0.1rem;
  width:80%;
  margin-left:5%;
}

@media screen and (max-width:600px){
  .About-title{
    font-size:2.3rem;
  }
  .About-section{
    font-size:1.4rem;
  }
  .About-tech-grid > p {
    font-size:1.3rem;
  }

}
@media screen and (max-width:400px){
   .About-title{
    font-size:2rem;
  }
  .About-section{
    font-size:1.1rem;
  }
  .About-tech-grid > p {
    font-size:1.1rem;
  }
}

@media screen and (max-width:350px){
  .About-title{
    font-size:2rem;
  }
  .About-section{
    font-size:1rem;
  }
  .About-tech-grid > p {
    font-size:1rem;
  }
}

@media screen and (min-width:1000px){
  .About-tech-grid-parent{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    max-height: fit-content;
    padding:1rem;
  }
  .About-tech-grid{
    border-radius:10px;
    border:1px solid transparent;
    color:white;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: left;
    grid-gap:5px;
  }
}