.Nav-main{
  width:100vw;
  min-height:4vh;
  top:0;
  background-image: linear-gradient(
  140deg,
      #CA4246 16%, 
      #eb866b 36%,
      #e79761 40%, 
      #F18F43 50%,
      #F18F43 60%, 
      #8B9862 90%, 
      #8B9862 100%
  );
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  position:sticky;
}

.Nav-home-button{
  height:100%;
  background-color: white(223, 245, 102);
  border:3px solid lavender;
  color:purple;
  font-weight: bold;
}

.Nav-home-button{
  cursor:pointer;
}
.Nav-list{
  list-style-type: none;
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top:0;
}
.Nav-item{
  margin-right:5vw;
  color:white;
  font-weight:bold;
  margin-top:1rem;
}

.Nav-item:hover{
  cursor: pointer;
  text-decoration: underline;
}


.Mobile-nav-main{
  display:flex;
  width:100%;
  min-height:7vh;
  justify-content: end;
  align-items: center;
}

/* Nav Mobile Menu Button */

.Mobile-nav-button {
  z-index: 12;
  cursor: pointer;
  transition: all 0.5s ease-out;
  margin-right:7%;
  margin-top:1.5%;
}
.Mobile-nav-button .Button-line {
  width: 28px;
  height: 3px;
  margin: 0 0 5px 0;
  background: white;
  transition: all 0.5s ease-out;
}

.close {
  transform: rotate(180deg);
  z-index:11;
}
.close:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
 
.close:nth-child(2) {
  opacity: 0;
}
 
.close:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

/*Nav Mobile Menu */
 
.Mobile-nav-menu{
  background-color: black;
  opacity: 0.9;
  min-height:100%;
  width:100vw;
  z-index:10;
  position:fixed;
  overflow-y: hidden;
  top:0;
  left:0;
  right:0;
  bottom:0;
  animation: menu-down 0.4s linear;
}

@keyframes menu-down{
  0%{
    margin-top:-30vh;
  }
  50%{
    margin-top:-10vh;
  }
  100%{
    margin-top:0;
  }
}

.Mobile-nav-menu-list{
  min-width:100%;
  min-height:100%;
  list-style-type:none;
  display:flex;
  flex-direction:column;
  justify-content: top;
  align-items: center;
  transition:all 2s ease-in-out;
  margin-left:-2em;
}

.Mobile-nav-menu-item{
  font-size:2rem;
  color:white;
  padding-top:5rem;
}

.Mobile-nav-menu-item:hover{
  cursor:pointer;
  text-decoration: underline;
}

