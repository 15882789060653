.Work-main{
  min-height:100vh;
  min-width:100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x:hidden;
}
.Work-section-div{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height:100vh;
  width:100%;
  border:2px solid white;
}

.Work-section-nest{
  min-height:98vh;
  width:98%;
  color:white;
  display: flex;
  flex-direction: column;
  align-items:center;
}

.Work-title-div{
  width:100%;
  display:flex;
  justify-content: center;
}

.Work-title{
  font-size:2.5rem;
  padding:0.5rem;
  width:85%;
  margin-bottom:0;
  border-top:2px solid white;
  border-bottom:2px solid white;
  background-image: linear-gradient(
    140deg,
      #CA4246 16%, 
      #E16541 26%, 
      #E16541 46%, 
      #E16541 83%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.Work-list-main{
  background-color: rgb(108, 78, 78);
  border:2px solid white;
  min-height:100%;
  width:100%;
  margin-top:1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Work-list-title{
  text-align: center;
  text-decoration: underline;
}
.Work-professional{
  background-color: rgb(173, 86, 86);
}
.Nest-professional{
  border-top:5px solid rgb(108, 78, 78) !important;
}

.Work-list-nest{
  border-top:5px solid #CA4246;
  margin-bottom:2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Work-list-nest-child{
  width:80%;
}
.Work-list-nest h1 {
  text-decoration: underline;
}
.Work-list-item{
  min-height:6rem;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.Work-list-item-nest{
  display:flex;
  justify-content: center;
  width:80%;
  padding:1rem;
  border-right:1px solid black;
  border-top:1px solid black;
}

.Work-list-p{
  min-width: 100%;
  max-width:100%;
  line-height:1.8rem;
  font-weight: bold;
  border:2px solid black;
  padding:2rem;
  display:flex;
  align-items: center;
}

.Work-list-buttons{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Work-list-buttons span{
  background-color: #8B9862;
  padding:0.4rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.Work-list-buttons span:hover{
  background-color:#758349;
}

.Work-list-tech{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-weight: 800;
  min-width:100%;
  min-height:100%;
  margin-top:1rem;
  border-top:2px solid#8B9862; 
}

.Work-list-tech span{
  text-align: center;
  margin-top:2rem;
}

@media screen and (max-width:600px){
  .Work-list-tech span{
    font-size: 0.9rem;
  }
  .Work-list-p{
    line-height:1.8rem;
    font-size: 1rem;
    margin-bottom: 0;
  }
  .Work-title{
    font-size:1.8rem;
  }
  .Work-list-title{
    font-size:1.5rem;
    text-align: left;
  }
  .Work-list-nest h1 {
    font-size: 1.7rem;
  }
  .Work-list-buttons span{
    padding:0.4rem;
    font-size: 1rem;
  }
}

@media screen and (max-width:450px){
  .Work-list-buttons span{
    padding:0.3rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width:350px){
  .Work-list-tech span{
    font-size: 0.7rem;
    margin-top:0.5rem;
  }
   .Work-list-p{
    line-height:1.5rem;
    font-size: 0.9rem;
  }
  .Work-title{
    font-size:1.7rem;
  }
  .Work-list-title{
    font-size:1.4rem;
    text-align: left;
  }
  .Work-list-nest h1 {
    font-size: 1.4rem;
  }
  .Work-list-buttons span{
    padding:0.3rem;
    font-size: 0.7rem;
  }
}

@media screen and (min-width:1000px){
  .Work-list-main{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
  }
 .Work-list-item{
    min-height:40rem;
    max-height: 40rem;
  }
  .Work-list-p{
    min-height:25rem !important;
  }
}